/**
 * Created by slava on 3/5/19
 */

cleverapps.styles.dialogues = {
    "finishLevel13": {
        dialogue: [
            {
                text: "Dialogues.FinishLevel13.Text1",
                person: { "role": "smith", "emotion": "sad" }
            },
            {
                text: "Dialogues.FinishLevel13.Text2",
                person: { "role": "king", "orientation": "right", "emotion": "talk" }
            }
        ],
        bundles: ["comicses", "dialogues", "game"]
    },

    "finishLevel17": {
        dialogue: [
            {
                text: "Dialogues.FinishLevel16.Text1",
                person: { "role": "woodcutter", "emotion": "happy" }
            }
        ],
        bundles: ["comicses", "dialogues", "game"]
    },

    "finishLevel19": {
        dialogue: [
            {
                text: "Dialogues.FinishLevel19.Text1",
                person: { "role": "king", "emotion": "sad" }
            },
            {
                text: "Dialogues.FinishLevel19.Text2",
                person: { "role": "purple", "orientation": "right", "emotion": "idle" }
            }
        ],
        bundles: ["comicses", "dialogues", "game"]
    },

    "finishLevel25": {
        dialogue: [
            {
                text: "Dialogues.FinishLevel25.Text1",
                person: { "role": "purple", "emotion": "sad" }
            },
            {
                text: "Dialogues.FinishLevel25.Text2",
                person: { "role": "purple", "orientation": "right", "emotion": "talk" }
            }
        ],
        bundles: ["comicses", "dialogues", "game"]
    },

    "finishLevel33": {
        dialogue: [
            {
                text: "Dialogues.FinishLevel33.Text1",
                person: { "role": "smith", "emotion": "sad" }
            },
            {
                text: "Dialogues.FinishLevel33.Text2",
                person: { "role": "king", "orientation": "right", "emotion": "talk" }
            }
        ],
        bundles: ["comicses", "dialogues", "game"]
    },

    "finishLevel42": {
        dialogue: [
            {
                text: "Dialogues.FinishLevel42.Text1",
                person: { "role": "king", "emotion": "talk" }
            },
            {
                text: "Dialogues.FinishLevel42.Text2",
                person: { "role": "king", "orientation": "right", "emotion": "talk" }
            }
        ],
        bundles: ["comicses", "dialogues", "game"]
    },

    "finishLevel49": {
        dialogue: [
            {
                text: "Dialogues.FinishLevel49.Text1",
                person: { "role": "woodcutter", "emotion": "sad" }
            },
            {
                text: "Dialogues.FinishLevel49.Text2",
                person: { "role": "smith", "orientation": "right", "emotion": "talk" }
            }
        ],
        bundles: ["comicses", "dialogues", "game"]
    },

    "finishLevel56": {
        dialogue: [
            {
                text: "Dialogues.FinishLevel56.Text1",
                person: { "role": "smith", "emotion": "sad" }
            },
            {
                text: "Dialogues.FinishLevel56.Text2",
                person: { "role": "woodcutter", "orientation": "right", "emotion": "talk" }
            }
        ],
        bundles: ["comicses", "dialogues", "game"]
    },

    "finishLevel69": {
        dialogue: [
            {
                text: "Dialogues.FinishLevel69.Text1",
                person: { "role": "king", "emotion": "talk" }
            },
            {
                text: "Dialogues.FinishLevel69.Text2",
                person: { "role": "purple", "orientation": "right", "emotion": "idle" }
            }
        ],
        bundles: ["comicses", "dialogues", "game"]
    },

    "finishLevel76": {
        dialogue: [
            {
                text: "Dialogues.FinishLevel76.Text1",
                person: { "role": "smith", "emotion": "talk" }
            },
            {
                text: "Dialogues.FinishLevel76.Text2",
                person: { "role": "woodcutter", "orientation": "right", "emotion": "talk" }
            },
            {
                text: "Dialogues.FinishLevel76.Text3",
                person: { "role": "smith", "emotion": "talk" }
            },
            {
                text: "Dialogues.FinishLevel76.Text4",
                person: { "role": "woodcutter", "orientation": "right", "emotion": "talk" }
            }
        ],
        bundles: ["comicses", "dialogues", "game"]

    },

    "finishLevel89": {
        dialogue: [
            {
                text: "Dialogues.FinishLevel89.Text1",
                person: { "role": "king", "emotion": "talk" }
            },
            {
                text: "Dialogues.FinishLevel89.Text2",
                person: { "role": "purple", "orientation": "right", "emotion": "idle" }
            },
            {
                text: "Dialogues.FinishLevel89.Text3",
                person: { "role": "king", "emotion": "talk" }
            },
            {
                text: "Dialogues.FinishLevel89.Text4",
                person: { "role": "purple", "orientation": "right", "emotion": "happy" }
            }
        ],
        bundles: ["comicses", "dialogues", "game"]

    },

    "finishLevel91": {
        dialogue: [
            {
                text: "Dialogues.FinishLevel91.Text1",
                person: { "role": "smith", "emotion": "talk" }
            },
            {
                text: "Dialogues.FinishLevel91.Text2",
                person: { "role": "woodcutter", "orientation": "right", "emotion": "talk" }
            },
            {
                text: "Dialogues.FinishLevel91.Text3",
                person: { "role": "smith", "emotion": "talk" }
            },
            {
                text: "Dialogues.FinishLevel91.Text4",
                person: { "role": "woodcutter", "orientation": "right", "emotion": "happy" }
            }
        ],
        bundles: ["comicses", "dialogues", "game"]
    }
};
