/**
 * Created by slava on 8/5/19
 */

["a", "b", "c", "d", "e", "w"].forEach(function (color) {
    Game.GOAL_ENEMY_TYPES[color] = [0, 6, 7, 8];
});

Game.GOAL_ENEMY_TYPES[IceTile.GOAL_ID] = 2;
Game.GOAL_ENEMY_TYPES[MouseCell.GOAL_ID] = 1;
Game.GOAL_ENEMY_TYPES[FishCell.GOAL_ID] = 3;
Game.GOAL_ENEMY_TYPES[FlowerCell.GOAL_ID] = 4;
Game.GOAL_ENEMY_TYPES[NutCell.GOAL_ID] = 5;
Game.GOAL_ENEMY_TYPES[Mark.GOAL_ID] = 9;
Game.GOAL_ENEMY_TYPES[DragonCell.GOAL_ID] = 11;
Game.GOAL_ENEMY_TYPES[CandleCell.GOAL_ID] = 10;
Game.GOAL_ENEMY_TYPES[CookieManCell.GOAL_ID] = 15;
Game.GOAL_ENEMY_TYPES[GingerHouseCell.GOAL_ID] = 12;
Game.GOAL_ENEMY_TYPES[CakeCell.GOAL_ID] = 13;
Game.GOAL_ENEMY_TYPES[MuffinGoalCell.GOAL_ID] = 14;
Game.GOAL_ENEMY_TYPES[WaffleCell.GOAL_ID] = 16;
Game.GOAL_ENEMY_TYPES[RabbitTile.GOAL_ID] = 19;
Game.GOAL_ENEMY_TYPES[LollipopCell.GOAL_ID] = 18;
Game.GOAL_ENEMY_TYPES[DogCell.GOAL_ID] = 17;
Game.GOAL_ENEMY_TYPES[TruffleCell.GOAL_ID] = 14;
Game.GOAL_ENEMY_TYPES[ColorCookieManCell.GOAL_ID] = 12;
Game.GOAL_ENEMY_TYPES[CoinCell.GOAL_ID] = 20;

Goals.WAIT_DURATION = 0;
Goals.DEFAULT_NEXT_CELL_DURATION = 0.1;