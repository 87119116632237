/**
 * Created by mac on 5/6/17.
 */

cleverapps.styles.COLORS = cleverapps.overrideColors(cleverapps.styles.COLORS, {
    LIGHT_BROWN: new cc.Color(206, 157, 101, 255),
    HERO_STATS_UPGRADE: new cc.Color(46, 214, 0, 255)
});

cleverapps.styles.DECORATORS = cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    WINDOW_TEXT_STROKE: {
        color: cleverapps.styles.COLORS.BLACK,
        size: 2
    },

    REWARDS_TEXT_STROKE: {
        color: cleverapps.styles.COLORS.REWARDS_TEXT_STROKE_COLOR,
        size: 3
    },

    DARK_RED_TEXT_STROKE: {
        color: new cc.Color(124, 33, 21, 255),
        size: 1
    },

    BOOSTER_AMOUNT_STROKE: {
        color: new cc.Color(142, 89, 50, 255),
        size: 3
    },

    DAILY_TASKS_TIMER_STROKE: {
        color: cleverapps.styles.COLORS.BLACK,
        size: 1
    },

    BOOSTERS_BEFORE_TEXT_STROKE: {
        color: new cc.Color(152, 56, 36, 255),
        size: 3
    },

    CONGRATS_TEXT_STROKE: {
        color: cleverapps.styles.COLORS.BLACK,
        size: 4
    },

    CONGRATS_TEXT_SHADOW: {
        color: cleverapps.styles.COLORS.BLACK,
        direction: cc.size(1, -8),
        blur: 1
    }
});

cleverapps.styles.UI.glyphScale = undefined;

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CONGRATS_MESSAGE_FONT: {
        size: 90,
        color: cleverapps.styles.COLORS.CONGRATS_BLUE,
        stroke: cleverapps.styles.DECORATORS.CONGRATS_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.CONGRATS_TEXT_SHADOW
    },

    VICTORY_TEXT: {
        size: 70,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.WINDOW_TEXT_STROKE
    },

    RPG_LIFE_VIEW_TEXT: {
        name: "default",
        size: 24
    },

    EPISODE_CLOUD_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.WINDOW_TEXT_STROKE
    },

    EPISODE_LABEL: {
        size: 35,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.WINDOW_WHITE_TEXT_STROKE
    },

    PACKWINDOW_TITLE_TEXT: {
        size: 65,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.WINDOW_TEXT_STROKE
    },

    PACKWINDOW_LABEL_TEXT: {
        name: "nostroke",
        size: 60,
        color: cleverapps.styles.COLORS.WHITE
    },

    PACK_PRICE: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_BROWN
    },

    TRANSITION_LOADING_TEXT: {
        size: 40
    },

    BOOSTERS_BEFORE_UNLOCK: {
        shadow: cleverapps.styles.DECORATORS.IMAGE_FONT_SHADOW
    },

    MINIGAME_REWARD: {
        shadow: cleverapps.styles.DECORATORS.IMAGE_FONT_SHADOW
    }
});

cleverapps.styles.UI.BoostersPositions = [
    [
        { x: { align: "center", dx: -250 }, y: { align: "center", dy: 4 } },
        { x: { align: "center", dx: -100 }, y: { align: "center", dy: 4 } },
        { x: { align: "center", dx: 50 }, y: { align: "center", dy: 4 } }
    ],
    [
        { x: { align: "center", dx: -30 }, y: { align: "center", dy: 0 } },
        { x: { align: "center", dx: -30 }, y: { align: "center", dy: -250 } },
        { x: { align: "center", dx: -30 }, y: { align: "center", dy: -500 } }
    ],
    [
        { x: { align: "center", dx: -30 }, y: { align: "center", dy: 50 } },
        { x: { align: "center", dx: -30 }, y: { align: "center", dy: -200 } },
        { x: { align: "center", dx: -30 }, y: { align: "center", dy: -450 } }
    ]
];

cleverapps.styles.UI.BoostersViewPosition = [
    [
        { x: { align: "center" }, y: { align: "bottom" } },
        { x: { align: "right" }, y: { align: "center" } },
        { x: { align: "right" }, y: { align: "center" } }
    ],
    [
        { x: { align: "center", dx: 100 }, y: { align: "bottom" } },
        { x: { align: "right" }, y: { align: "center", dy: -360 } },
        { x: { align: "right" }, y: { align: "center", dy: -360 } }
    ],
    [
        { x: { align: "center", dx: 90 }, y: { align: "bottom" } },
        { x: { align: "right" }, y: { align: "center", dy: -150 } },
        { x: { align: "right" }, y: { align: "center", dy: -150 } }
    ],
    [
        { x: { align: "center" }, y: { align: "bottom" } },
        { x: { align: "right" }, y: { align: "center" } },
        { x: { align: "right" }, y: { align: "center" } }
    ]
];

cleverapps.overrideStyles(cleverapps.styles.GameScene, {
    actor: {
        mobileScale: 0.78,
        boostersAmountPosition: [
            {
                wideModePositions: [
                    {
                        x: { align: "center", dx: -23 },
                        y: { align: "bottom", dy: 70 }
                    },
                    {
                        x: { align: "right", dx: -50 },
                        y: { align: "center", dy: -55 }
                    },
                    {
                        x: { align: "right", dx: -50 },
                        y: { align: "center", dy: 180 }
                    }
                ]
            },
            {
                wideModePositions: [
                    {
                        x: { align: "center", dx: 137 },
                        y: { align: "bottom", dy: 70 }
                    },
                    {
                        x: { align: "right", dx: -50 },
                        y: { align: "center", dy: 75 }
                    },
                    {
                        x: { align: "right", dx: -50 },
                        y: { align: "center", dy: 410 }
                    }
                ]
            },
            {
                wideModePositions: [
                    {
                        x: { align: "center", dx: 147 },
                        y: { align: "bottom", dy: 70 }
                    },
                    {
                        x: { align: "right", dx: -50 },
                        y: { align: "center", dy: 125 }
                    },
                    {
                        x: { align: "right", dx: -50 },
                        y: { align: "center", dy: 400 }
                    }
                ]
            },
            {
                wideModePositions: [
                    {
                        x: { align: "center", dx: 227 },
                        y: { align: "bottom", dy: 70 }
                    },
                    {
                        x: { align: "right", dx: -50 },
                        y: { align: "center", dy: 275 }
                    },
                    {
                        x: { align: "right", dx: -50 },
                        y: { align: "center", dy: 350 }
                    }
                ]
            }
        ]
    }
});

PagingScroll.VISIBLE_PAGES = 3;

cleverapps.overrideStyles(cleverapps.styles.PagingScrollView, {
    pageHeight: cleverapps.styles.SCENE_HEIGHT / 3,
    firstOffset: 480,

    parallax: {
        speed: [1, 0.5],
        zIndex: [-1000, 1000],
        patternSize: {
            width: 512,
            height: 512
        }
    },

    comingSoonExtraLength: [200, 200, 200]
});

cleverapps.overrideStyles(cleverapps.styles.IslandView, {
    positions: {
        0: {
            x: -180,
            y: 0
        },

        1: {
            x: 180,
            y: 0
        }
    },

    positionsMobile: {
        0: {
            x: -30,
            y: 0
        },

        1: {
            x: 30,
            y: 0
        }
    },

    status: {
        positions: {
            0: {
                x: { align: "left", dx: 0 },
                y: { align: "top", dy: 0 }
            },

            1: {
                x: { align: "right", dx: 0 },
                y: { align: "top", dy: 0 }
            }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LevelsView, {
    levels_1: [],
    levels_2: []
});

cleverapps.overrideStyles(cleverapps.styles.FieldView, {
    margins: [{
        top: 380,
        bottom: 260,
        left: 10,
        right: 10
    }, {
        top: 420,
        bottom: 20,
        left: 40,
        right: 320
    }, {
        top: 120,
        bottom: 20,
        left: 350,
        right: 370
    }],

    slideAnimation: {
        field: {
            delay: 1.0
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.Exclamations.heroes, {
    // width: 800,
    "warning": {
        color: cleverapps.styles.COLORS.CONGRATS_LIGHT_GREEN
    },
    "wow": {
        color: cleverapps.styles.COLORS.CONGRATS_ORANGE
    }
});

cleverapps.overrideStyles(cleverapps.styles.HeroesScene, {
    heroBuilding: {
        width: 120,
        height: 120,
        scale: 1
    },

    buttons: {
        width: 220
    }
});

(function () {
    var slots = [];
    [185, -110, -405].forEach(function (y) {
        [-150, 150].forEach(function (x) {
            slots.push({
                x: x,
                y: y
            });
        });
    });

    cleverapps.styles.HeroesScene.heroBuildings = ["e", "d", "a", "b", "c", "w"].map(function (color, index) {
        return {
            color: color,
            x: { align: "center", dx: slots[index].x },
            y: { align: "center", dy: slots[index].y }
        };
    });
}());

cleverapps.overrideStyles(cleverapps.styles.GoalsView, {
    zOrders: [5, 4, 3],
    amountGoalsScales: [[false, 0.9, 0.84], false, false],
    amountGoalsDelta: [[false, { dx: 0, dy: -14 }, { dx: 0, dy: -20 }], false, false]
});

cleverapps.overrideStyles(cleverapps.styles.LineBoosterView, {
    animation: {
        y: -450
    },

    timeScale: {
        scale: 1
    }
});

cleverapps.overrideStyles(cleverapps.styles.GoalsView, {
    positionNoMission: [{
        x: { align: "center", dx: 14 },
        y: { align: "top", dy: 7 }
    }, {
        x: { align: "center", dx: -100 },
        y: { align: "top", dy: -28 }
    }, {
        x: { align: "left", dx: -312 },
        y: { align: "center", dy: 0 },
        rotation: -90
    }],

    positionWithMission: [
        [
            {
                x: { align: "center", dx: 14 },
                y: { align: "top", dy: 7 }
            }, {
                x: { align: "center", dx: 30 },
                y: { align: "top", dy: 7 }
            }, {
                x: { align: "center", dx: 50 },
                y: { align: "top", dy: 7 }
            }
        ],
        {
            x: { align: "center", dx: -50 },
            y: { align: "top", dy: -28 }
        }, {
            x: { align: "left", dx: -312 },
            y: { align: "center", dy: 100 },
            rotation: -90
        }],

    sizes: {
        noMission: [{
            height: 350,
            width: 650
        }, {
            height: 350,
            width: 850
        }, {
            height: 350,
            width: 950
        }],

        withMission: [{
            height: 350,
            width: 620
        }, {
            height: 350,
            width: 760
        }, {
            height: 350,
            width: 950
        }]
    }
});

cleverapps.overrideStyles(cleverapps.styles.ProlongationWindow, {
    goals: {
        padding: {
            y: 50
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.UI.MovesView, {
    position: [{
        x: { align: "left", dx: 85 },
        y: { align: "center", dy: 0 }
    }, {
        x: { align: "right", dx: -100 },
        y: { align: "top", dy: -50 }
    }, {
        x: { align: "center", dx: -15 },
        y: { align: "center", dy: 230 }
    }],

    scale: [1, 1, 1]
});

cleverapps.overrideStyles(cleverapps.styles.CloversView, {
    width: 259,
    height: 152,

    icon: {
        x: { align: "center", dx: -30 },
        y: { align: "center", dy: 70 }
    },

    text: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 50 }
    },

    position: [
        {
            x: { align: "center", dx: -250 },
            y: { align: "top", dy: -112 }
        },
        {
            x: { align: "center", dx: -470 },
            y: { align: "top", dy: -167 }
        },
        {
            x: { align: "left", dx: 44 },
            y: { align: "center", dy: -540 },
            rotation: 90
        }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.HardLevelIcon, {
    parentSceneScene: true
});

cleverapps.overrideStyles(cleverapps.styles.PassLevelReward, {
    positions: [
        { x: { align: "left", dx: -50 }, y: { align: "bottom", dy: 85 } },
        { x: { align: "left", dx: -80 }, y: { align: "bottom", dy: -40 } },
        { x: { align: "center", dx: 230 }, y: { align: "bottom", dy: -240 } }
    ]
});

cleverapps.styles.PassLevelReward.prize.icon.scale[PassLevelReward.BOOSTER] = 0.42;

cleverapps.overrideStyles(cleverapps.styles.FieldBoosterView, {
    amount: {
        x: -120,
        y: 20
    },

    price: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -30 }
    },

    scales: [0.74, 1, 1],
    background: undefined,
    locked: false
});

cleverapps.overrideStyles(cleverapps.styles.MultiColorCellView, {
    centerAnimation: {
        scale: 1
    }
});

cleverapps.overrideStyles(cleverapps.styles.BoosterAmountView, {
    background: {
        scale: 1.2
    }
});

cleverapps.overrideStyles(cleverapps.styles.MultiColorCellView, {
    centerAnimation: false
});

cleverapps.overrideStyles(cleverapps.styles.RpgLifeView, {
    y: { align: "bottom", dy: -60 }
});

cleverapps.overrideStyles(cleverapps.styles.BoosterInfoView, {
    sizes: [
        {
            width: "full",
            addx: 90,
            height: 220
        },
        {
            width: "field",
            height: 180
        },
        {
            width: "field",
            height: 180
        }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.BaseGoalView, {
    enemies: [
        {
            spine: bundles.enemy0.jsons.enemy0,
            sfx: {
                attack: bundles.game.urls.enemy_attack_0,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy1.jsons.enemy1,
            sfx: {
                attack: bundles.game.urls.enemy_attack_1,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy2.jsons.enemy2,
            sfx: {
                attack: bundles.game.urls.enemy_attack_2,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy3.jsons.enemy3,
            sfx: {
                attack: bundles.game.urls.enemy_attack_3,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy4.jsons.enemy4,
            sfx: {
                attack: bundles.game.urls.enemy_attack_4,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy5.jsons.enemy5,
            sfx: {
                attack: bundles.game.urls.enemy_attack_5,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy6.jsons.enemy6,
            sfx: {
                attack: bundles.game.urls.enemy_attack_6,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy7.jsons.enemy7,
            sfx: {
                attack: bundles.game.urls.enemy_attack_7,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy8.jsons.enemy8,
            sfx: {
                attack: bundles.game.urls.enemy_attack_8,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy9.jsons.enemy9,
            sfx: {
                attack: bundles.game.urls.enemy_attack_9,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy10.jsons.enemy10,
            sfx: {
                attack: bundles.game.urls.enemy_attack_3,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy11.jsons.enemy11,
            sfx: {
                attack: bundles.game.urls.enemy_attack_9,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy12.jsons.enemy12,
            sfx: {
                attack: bundles.game.urls.enemy_attack_1,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy13.jsons.enemy13,
            sfx: {
                attack: bundles.game.urls.enemy_attack_2,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy14.jsons.enemy14,
            sfx: {
                attack: bundles.game.urls.enemy_attack_8,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy15.jsons.enemy15,
            sfx: {
                attack: bundles.game.urls.enemy_attack_9,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy16.jsons.enemy16,
            sfx: {
                attack: bundles.game.urls.enemy_attack_4,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy17.jsons.enemy17,
            sfx: {
                attack: bundles.game.urls.enemy_attack_8,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy18.jsons.enemy18,
            sfx: {
                attack: bundles.game.urls.enemy_attack_1,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy19.jsons.enemy19,
            sfx: {
                attack: bundles.game.urls.enemy_attack_6,
                die: bundles.game.urls.enemy_die
            }
        },
        {
            spine: bundles.enemy20.jsons.enemy20,
            sfx: {
                attack: bundles.game.urls.enemy_attack_0,
                die: bundles.game.urls.enemy_die
            }
        }
    ],

    actor: {
        attacks: [Actor.ANIMATIONS.ATTACK1, Actor.ANIMATIONS.ATTACK2, Actor.ANIMATIONS.ATTACK3, Actor.ANIMATIONS.ATTACK4],
        spinePosition: {
            x: { align: "center" },
            y: { align: "center", dy: -75 }
        }
    }
});

BombByLeftMovesAnimation.POOL_SIZE = 8;
BombByLeftMovesView.DELAY = 800;

cleverapps.overrideStyles(cleverapps.styles.LoadingAnimation, {
    text: {
        y: { align: "center", dy: -40 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.BoosterCellView, {
    images: [bundles.game.frames.field_cellbooster_png, bundles.game.frames.field_linebooster_png, bundles.game.frames.field_colorbooster_png]
});

cleverapps.overrideStyles(cleverapps.styles.BombCellView, {
    skins: true,

    timer: {
        y: -18
    }
});

cleverapps.overrideStyles(cleverapps.styles.LanguageButton, {
    height: 90
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    LANTERN_TIMER_FONT: {
        size: 35,
        color: cleverapps.styles.COLORS.WHITE
    },

    BOMB_TIMER_TEXT: {
        name: "nostroke",
        color: cleverapps.styles.COLORS.WHITE,
        size: 35
    },

    PETARD_LIVES: {
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.overrideStyles(cleverapps.styles.OkLotteryIcon, {
    positions: {
        "bottom": {
            y: { align: "bottom", dy: -60 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LotteryIcon, {
    positions: {
        "bottom": {
            x: { align: "center", dx: 75 },
            y: { align: "bottom", dy: -60 },
            scale: 0.9
        },
        "icon": {
            x: { align: "left", dx: -10 },
            y: { align: "top", dy: 45 }
        }
    }
});

cleverapps.styles.CurrentEpisodeLabel = {
    label: {
        hardLevel: {
            alignment: {
                x: { align: "center" },
                y: { align: "center", dy: 25 }
            }
        },

        alignment: {
            x: { align: "center" },
            y: { align: "center", dy: -250 }
        },

        islandText: {
            font: cleverapps.styles.FONTS.EPISODE_LABEL,
            padding: -50,
            alignment: {
                x: { align: "center" },
                y: { align: "center", dy: 5 }
            }
        }
    },

    background: {
        image: bundles.main.frames.cur_ep_label_bg,
        width: 366,
        height: 74
    }
};

cleverapps.overrideStyles(cleverapps.styles.ProgressView, {
    x: { align: "center" },
    y: { align: "center", dy: -325 },

    bar: {
        width: 270
    },

    arrows: false
});

cleverapps.overrideStyles(cleverapps.styles.StarsProgressBar, {
    bar: {
        dy: 1
    }
});

cleverapps.overrideStyles(cleverapps.styles.PackWindow, {
    animation: {
        "default": {
            idleDelay: -0.7,
            dx: 0,
            dy: -20
        },

        "starterPack0": {
            idleAnimation: "2_idle",
            introAnimation: "2_start"
        },

        "starterPack": {
            idleAnimation: "2_idle",
            introAnimation: "2_start"
        },

        "starterPack2": {
            idleAnimation: "2_idle",
            introAnimation: "2_start"
        }
    },

    rewards: {
        delay: 1.2,
        duration: 0.675,
        fitTo: { width: 170, height: 55 },

        positions: {
            default: {
                "boosters": {
                    "5": {
                        x: 198,
                        y: 147
                    },
                    "6": {
                        x: -210,
                        y: 149
                    },
                    "7": {
                        x: -228,
                        y: -213
                    }
                },
                "hard": {
                    x: -6,
                    y: -105
                },
                "unlimitedLives": {
                    x: 250,
                    y: -213
                }
            }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.WindowGoalView, {
    width: 180,
    height: 230,
    icon: {
        y: { align: "center", dy: 15 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LoaderSceneBackground, {
    logoAnimation: {
        pos: {
            factorY: 0.775
        }
    }
});