/**
 * Created by slava on 3/5/19
 */
cleverapps.styles.comicses = {
    begin: {
        stages: [
            {
                showAnimation: "animation1",
                idleAnimation: "animation1_idle",
                dialogueStages: 2
            },
            {
                showAnimation: "animation2",
                idleAnimation: "animation2_idle",
                dialogueStages: 2
            }
        ],

        dialogue: [
            {
                text: "Comics.Begin.Text1",
                person: {
                    role: "purple",
                    orientation: "right",
                    emotion: "idle"
                }
            },
            {
                text: "Comics.Begin.Text2",
                person: {
                    role: "purple",
                    orientation: "right",
                    emotion: "idle"
                }
            },
            {
                text: "Comics.Begin.Text3",
                person: {
                    role: "purple",
                    orientation: "left",
                    emotion: "sad"
                }
            },
            {
                text: "Comics.Begin.Text4",
                person: {
                    role: "purple",
                    orientation: "left",
                    emotion: "talk"
                }
            }
        ],

        bundles: ["comicses"],
        json: bundles.comicses.jsons.begin_json,
        onFinish: "game"
    },

    finishEpisode: {
        stages: [
            {
                showAnimation: "animation",
                idleAnimation: "idle"
            }
        ],

        dialogue: [
            {
                text: "Comics.FinishEpisode.Text",
                person: false
            }
        ],

        bundles: ["comicses"],
        json: bundles.comicses.jsons.finish_episode_json,
        onFinish: "episodes"
    },

    "finishEpisode0": {
        stages: [
            {
                showAnimation: "animation1",
                idleAnimation: "animation1_idle"
            },
            {
                showAnimation: "animation2",
                idleAnimation: "animation2_idle"

            },
            {
                showAnimation: "animation3",
                idleAnimation: "animation3_idle"
            }
        ],

        dialogue: [
            {
                text: "Comics.FinishEpisode0.Text1",
                person: {
                    role: "king",
                    orientation: "left",
                    emotion: "talk"
                }
            },
            {
                text: "Comics.FinishEpisode0.Text2",
                person: {
                    role: "purple",
                    orientation: "right",
                    emotion: "sad"
                }
            },
            {
                text: "Comics.FinishEpisode0.Text3",
                person: {
                    role: "king",
                    orientation: "left",
                    emotion: "talk"
                }
            }
        ],

        bundles: ["comicses"],
        json: bundles.comicses.jsons.finish_episode_0_json,
        onFinish: "episodes"
    }
};

cleverapps.styles.comicses[ColorComponent.COLOR_A] = {
    stages: [
        {
            showAnimation: "animation1",
            idleAnimation: "animation1_idle"
        },
        {
            showAnimation: "animation2",
            idleAnimation: "animation2_idle"
        }
    ],

    dialogue: [
        {
            text: "Comics.BlueHero.Text1",
            person: false
        },
        {
            text: "Comics.BlueHero.Text2",
            person: false
        }
    ],

    bundles: ["comicses"],
    json: bundles.comicses.jsons.blue_json
};

cleverapps.styles.comicses[ColorComponent.COLOR_D] = {
    stages: [
        {
            showAnimation: "animation1",
            idleAnimation: "animation1_idle"
        },
        {
            showAnimation: "animation2",
            idleAnimation: "animation2_idle"
        }
    ],

    dialogue: [
        {
            text: "Comics.RedHero.Text1",
            person: false
        },
        {
            text: "Comics.RedHero.Text2",
            person: false
        }
    ],

    bundles: ["comicses"],
    json: bundles.comicses.jsons.red_json
};

cleverapps.styles.comicses[ColorComponent.COLOR_E] = {
    stages: [
        {
            showAnimation: "animation1",
            idleAnimation: "animation1_idle"
        },
        {
            showAnimation: "animation2",
            idleAnimation: "animation2_idle"
        }
    ],

    dialogue: [
        {
            text: "Comics.YellowHero.Text1",
            person: false
        },
        {
            text: "Comics.YellowHero.Text2",
            person: false
        }
    ],

    bundles: ["comicses"],
    json: bundles.comicses.jsons.yellow_json
};

cleverapps.styles.comicses[ColorComponent.COLOR_B] = {
    stages: [
        {
            showAnimation: "animation1",
            idleAnimation: "animation1_idle"
        },
        {
            showAnimation: "animation2",
            idleAnimation: "animation2_idle"
        }
    ],

    dialogue: [
        {
            text: "Comics.GreenHero.Text1",
            person: false
        },
        {
            text: "Comics.GreenHero.Text2",
            person: false
        }
    ],

    bundles: ["comicses"],
    json: bundles.comicses.jsons.green_json
};
