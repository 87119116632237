/**
 * Created by ivan on 02.03.18.
 */

BaseCellViewStartMovingSuper = BaseCellViewStartMoving;
BaseCellViewStartMoving = function (destination, options) {
    BaseCellViewStartMovingSuper.apply(this, arguments);

    if (options.shuffle) {
        this.stopAllActions();

        this.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.RotateBy(options.moveInterval / 2, -480),
                new cc.ScaleTo(options.moveInterval / 2, 0.1)
            ),
            new cc.CallFunc(function () {
                this.setPosition(BaseCellView.alignInTheGrid(destination.x, destination.y));
            }.bind(this)),
            new cc.Spawn(
                new cc.RotateBy(options.moveInterval / 2, 480),
                new cc.ScaleTo(options.moveInterval / 2, 1)
            )
        ));

        var goalCoefComponent = this.cell.findComponent(GoalCoefComponent);
        var coefView = goalCoefComponent && goalCoefComponent.view;
        if (coefView) {
            coefView.runAction(new cc.Sequence(
                new cc.FadeOut(0.1),
                new cc.DelayTime(options.moveInterval - 0.2),
                new cc.FadeIn(0.1)
            ));
        }
    }
};

cleverapps.overrideStyles(cleverapps.styles.BaseCellView, {
    wrongMoveAnimationName: "default",
    wrongMove: {
        move: 30
    }
});
