/**
 * Created by decipaliz on 12.03.2024
 */

Match3.prototype.showScreen = function (f, silent) {
    this.boosters.trigger("hide", true);
    this.trigger("showPanels", true);
    this.trigger("showMovesView", silent);
    this.trigger("showActor", silent);

    cleverapps.meta.compound(f, [
        function (f) {
            this.goals.trigger("showUpAnimationStart", f, silent);

            if (silent) {
                return;
            }

            this.trigger("startFilmEffect", {
                scale: 1.4,
                layerTimeout: 0.3,
                scaleTimeout: 1,
                onClick: cleverapps.once(function () {
                    this.goals.trigger("stopShowUp");
                    f();
                }.bind(this))
            });
        }.bind(this),

        function (f) {
            this.trigger("stopFilmEffect");
            this.trigger("moveActor", silent);
            this.boosters.trigger("show", silent);
            f();
        }.bind(this),

        function (f) {
            this.field.trigger("showUpFloor", f, silent);
        }.bind(this),

        function (f) {
            this.field.showUpCells(f, silent);
        }.bind(this)
    ]);
};
