/**
 * Created by iamso on 08.05.19.
 */

cleverapps.overrideStyles(HeroesLibrary.byColors, {
    b: {
        shootFlyDuration: function () {
            return 1;
        },

        shootAlgorithm: "jump",
        action_delay: 175
    },

    c: {
        action_timeout: 1000
    },

    a: {
        action_timeout: 1100
    },

    d: {
        action_timeout: 700
    },

    e: {
        action_timeout: 1200
    }
});
