/**
 * Created by slava on 2/5/19
 */

GameScene.prototype.addMovesView = function () {
    var movesView = new MovesView(this.game, this.actor);
    if (movesView) {
        this.movesView = movesView;
        this.movesView.actor = this.actor;
        this.addChild(movesView);
    }
};

MovesView.prototype.ctor = cleverapps.extendFunc(MovesView.prototype.ctor, function (game, actor) {
    this.actor = actor;

    this._super(game);

    cleverapps.aims.registerTarget("moves", this.actor, {
        controls: "panel_controls",
        flyingUnderShadow: true,
        withoutDelta: true
    });
});

MovesView.prototype.create = function () {
    if (this.game.moves > 0) {
        this.life = new RpgLifeView({
            icon: new cc.Sprite(bundles.game.frames.lifebar_life_png),
            amount: this.game.moves,
            maxAmount: this.game.beginMoves,
            object: this.actor
        });

        cleverapps.tooltipManager.create(this.life.bar, {
            text: Messages.get("MovesTooltip"),
            location: cleverapps.UI.Tooltip.LOCATION_ABOVE
        });
    }
};

MovesView.prototype.setStyle = function () {
};

MovesView.prototype.updateMoves = function (moves, movesDiff, options) {
    if (this.life) {
        this.life.updateAmount(moves, options);
    }
};

MovesView.prototype.onShowUpAnimationStart = function (silent) {
    if (this.life) {
        this.life.baseScale = this.life.getScale();
        this.life.setVisible(true);

        if (!silent) {
            this.life.setScale(0);
            this.showUpAction = new cc.Sequence(
                new cc.DelayTime(3),
                new cc.ScaleTo(0.3, this.life.baseScale)
            ).setFinalize(function () {
                this.life.setScale(this.life.baseScale);
                delete this.showUpAction;
            }.bind(this));
            this.life.runAction(this.showUpAction);
        }
    }
};

MovesView.prototype.completeAnimationOnResize = function () {
    if (this.showUpAction) {
        this.life.stopAction(this.showUpAction);
    }
};

MovesView.prototype.upForTutorial = function () {
    this.replaceParentSamePlace(Game.levelTutorialView);
    this.actor.replaceParentSamePlace(Game.levelTutorialView);
};

MovesView.prototype.backFromTutorial = function () {
    var scene = cleverapps.scenes.getRunningScene();
    this.replaceParentSamePlace(scene);
    this.actor.replaceParentSamePlace(scene);
};

MovesView.prototype.hide = function () {};

MovesView.ANIMATE_COLLECT_DELAY = 1000;